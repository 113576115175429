<template>
  <UCard
    :ui="{
      base: 'relative',
      body: {
        padding: 'p-0 sm:p-0',
      },
      ring: 'ring-0',
      rounded: 'rounded-[14px]',
    }"
    class="relative card-popular col-span-12 sm:col-span-6 lg:col-span-3"
  >
    <ImagePreset
      :class="['rounded-t-[14px] max-h-52 object-contain', background]"
      :src="imageUrl"
      loading="eager"
      fetchpriority="high"
      alt="Khoá học trên kungfutech"
    />
    <div class="flex justify-center -mt-5">
      <div
        class="flex items-center bg-gray-50 dark:bg-gray-950 gap-1 pl-1 pr-3 py-1 rounded-full"
      >
        <ClientOnly>
          <UAvatarGroup size="sm" :max="4">
            <LazyUAvatar
              v-for="index in 4"
              :key="index"
              :src="`/images/home/blocks/course/user-course-${getRandomInt(1, 10)}.webp`"
              :alt="`User ${index} kungfutech`"
              class="cursor-pointer"
              loading="lazy"
            /> </UAvatarGroup
        ></ClientOnly>
        <span class="text-md font-light text-gray-500 lowercase"
          >+ 10K {{ LL.students() }}</span
        >
      </div>
    </div>
    <h3
      class="px-4 mt-6 text-xl leading-[22px] text-cyan-800 dark:text-cyan-200 font-bold"
    >
      {{ title }}
    </h3>
    <p class="px-4 text-base text-gray-500 dark:text-gray-500 line-clamp-3 my-4">
      {{ description }}
    </p>
    <div class="px-4 pb-6 flex items-center justify-between">
      <p class="font-bold text-base leading-6 text-pink-500 dark:text-pink-500">
        {{ LL.free() }}
      </p>
      <UButton
        :to="redirectLink"
        class="text-white text-sm leading-5 font-medium bg-cyan-500 hover:bg-cyan-600"
      >
        {{ LL.goto_learn() }}
      </UButton>
    </div>
  </UCard>
</template>

<script setup lang="ts">
import { getRandomInt } from "@techmely/utils";
import { typesafeI18n } from "~/locales/i18n-vue";

type Props = {
  title: string;
  description: string;
  imageUrl: string;
  redirectLink: string;
  background: string;
};

defineProps<Props>();
const { LL } = typesafeI18n();
</script>
<style lang="scss" scoped>
.card-popular {
  box-shadow: 0px 10px 60px 0px rgba(38, 45, 118, 0.08);
}
.dark {
  .card-popular {
    box-shadow: 0px 10px 60px 0px rgba(255, 255, 255, 0.08);
  }
}
</style>
